import React from 'react';
import Navbar from './Navbar';
import '../styles/global.css'
import LoggedOut from './LoggedOut';
import { useBrain } from '../../Brain';
import useEnsureMobile from '../functions/useEnsureMobile';
import Lottie from 'react-lottie';
import portraitModeAni from '../images/portraitMode.json'

function Layout({ children }) {

    const { user, navbarHidden } = useBrain()

    const { ensureMobile, portraitMode } = useEnsureMobile('mobile')

    const lottieOptions = {
        loop: true,
        autoplay: true,
        animationData: portraitModeAni,
        renderer: 'svg'
      }

    return (

        <div className="globalLayout">


            {!(ensureMobile && !portraitMode) &&
                <>
                    {!navbarHidden && <Navbar />}

                    <div className="pageContent">
                        {!user && <LoggedOut />}
                        {user && children}
                    </div>

                </>
            }

            {ensureMobile && !portraitMode &&

                <div className="landscapePrompt" >
                  
                    <Lottie options={lottieOptions} height={200} width={200} />
                   
                </div>

            }

        </div>


    );
}

export default Layout;