import React from 'react';
import { useState, useEffect } from 'react';
import netlifyIdentity from 'netlify-identity-widget'
import { navigate } from 'gatsby';

const useNetlifyIdentity = () => {

    //------------------------------------------------------------------------------------------------------
    // Netlify Identity related code -------------------------------------------------------


    //variables for states of user, or any error that may occur 
    const [user, setUser] = useState(null)
    const [error, setError] = useState(null)

    //state boolean to indicate whether Netlify identity is ready to be used. NEEDS TO BE 'false' for production! If this causes trouble in development mode, put it to 'true' for the time being.
    const [authReady, setAuthReady] = useState(false)

    let refreshInterval;


    //a useEffect that only runs once, getting ready all the things we need for Netlify identity
    useEffect(() => {


        //event listener: what should happen when someone logs in
        netlifyIdentity.on('login', (user) => {

            // console.log('login event')

            //set user state to save user info
            setUser(user)


            //close Netlify login dialogue
            netlifyIdentity.close()

            if (user && user.app_metadata && user.app_metadata.roles && user.app_metadata.roles[0] === 'authenticatedUser') {

                const currentPath = window.location.pathname

                if (currentPath === '/') {
                    // console.log('forwarding existing user (before granted function)')
                    navigate('/main')
                }

                return


            }


            //creating a url to call the 'getaccess' function that includes the (to be checked) user's email
            const urlString = '/.netlify/functions/check-access'

            const token = user && user.token.access_token

            // calling the function with said url
            fetch(urlString, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
                .then(res => {

                    //throwing error if no result could be generated
                    if (!res.ok) {
                        throw Error('Log in to see content')
                    }

                    //otherwise return result and json'ify it
                    return res.json()
                })

                .then(data => {

                    //if access granted, we navigate to the protected page
                    if (data.granted) {

                        // console.log('access granted')


                        //check if current path is /
                        const currentPath = window.location.pathname

                        if (currentPath === '/' && data.authenticatedUser) {
                            // console.log('forwarding user (after granted function)')
                            navigate('/main')
                        }

                    }

                    //if function did NOT grant access, we set access/error/loginFailed states accordingly and log out user
                    else {
                        netlifyIdentity.logout()
                        alert('Access denied.');

                    }
                })

                //catch error if there is one
                .catch((err) => {
                    setError(err.message)
                })



            // console.log('login event')
            // console.log('user is: ' + JSON.stringify(user))

        })

        //event listener: what should happen when someone logs out
        netlifyIdentity.on('logout', () => {

            //set user state back to null
            setUser(null)
            // console.log('logout event')

            navigate('/')


        })

        //event listener: what should happen once Netlify identity has been initialized
        netlifyIdentity.on('init', (user) => {

            // user && user.app_metadata && user.app_metadata.roles && user.app_metadata.roles[0] && console.log(user.app_metadata.roles[0])

            setAuthReady(true);


            if (user) {
                setUser(user);


            }

        })


        //initialize Netlify identity with first render. Note: it's important to initialize Netlify identity only here, AFTER we have defined all the above event listeners.

        netlifyIdentity.init()




    }, [])

    useEffect(() => {

        // Set up the interval to check token expiration

        if (user && user.token && !refreshInterval) {
            refreshInterval = setInterval(() => {

                const tokenExpiry = user.token.expires_at / 1000; // moment of expiry in seconds
                const now = Date.now() / 1000; // current moment in seconds

                // Refresh the token 5 minutes before expiration
                if (tokenExpiry - now < 300) {
                    netlifyIdentity.refresh().then((refreshedUser) => {
                        console.log('Token refreshed');
                        setUser(refreshedUser);
                    }).catch((error) => {
                        console.error('Error refreshing token:', error);
                    });
                }
            }, 60000); // Check every minute
        }

    }, [user]);

    //use these variables for onClick actions (click on button x to log in)
    const login = () => {
        netlifyIdentity.open()
    }

    //use these variables for onClick actions (click on button x to log oout)

    const logout = () => {
        netlifyIdentity.logout()

    }


    //END of Netlify Identity related code
    //----------------------------------------------------------------------------------------------------------


    return { authReady, user, error, login, logout }
}

export default useNetlifyIdentity;