import React from 'react';
import { useBrain } from '../../Brain';
import * as localStyles from '../styles/loggedOut.module.css';

const LoggedOut = () => {

    const { user, login, loginFailed } = useBrain();

    return (
        <div className={localStyles.loggedOut}>
            {!user && !loginFailed && <p>Enter site by signing-up / logging in with your work e-mail address</p>
            }

            {/* {user && access && <p>Hello – logged IN as {user.email}</p>} */}

            {loginFailed && <p>Access denied – please log in using your work e-mail address</p>}

            {!user && <button onClick={login}>Enter</button>}
        </div>
    );
}

export default LoggedOut;