import React from "react";
import { useState, useEffect } from "react";

const useEnsureMobile = (type = 'tablet') => {

    const [ensureMobile, setEnsureMobile] = useState()
    const [portraitMode, setPortraitMode] = useState()

    var pixelWidth = 0

    switch (type) {
        case 'mobile':
            pixelWidth = 480
            break;
        case 'tabletSmall':
            pixelWidth = 767
            break;
        case 'tablet':
            pixelWidth = 1024
            break;
        case 'desktop':
            pixelWidth = 1280
            break;

    }

    // Mobile (Smartphone) max-width: 480px
    // Low Resolution Tablets and ipads max-width: 767px
    // Tablets Ipads portrait mode max-width:1024px
    // Desktops max-width:1280px
    // Huge size (Larger screen) max-width: 1281px and greater


    useEffect(() => {

        //check mobile device

        if (window.matchMedia && window.matchMedia(`(max-device-width: ${pixelWidth}px)`).matches) {
            setEnsureMobile(true)

        }

        else {
            setEnsureMobile(false)
        }

        window.matchMedia(`(max-device-width: ${pixelWidth}px)`).addEventListener('change', e => {
            setEnsureMobile(e.matches)

        });


        //check portrait mode

        if (window.matchMedia && window.matchMedia('(orientation:portrait)').matches) {
            setPortraitMode(true)

        }

        else {
            setPortraitMode(false)
        }

        window.matchMedia('(orientation:portrait)').addEventListener('change', e => {
            setPortraitMode(e.matches)

        });

    }, [])

    return { ensureMobile, portraitMode }



}

export default useEnsureMobile;



