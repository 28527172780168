exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-main-alt-js": () => import("./../../../src/pages/main/alt.js" /* webpackChunkName: "component---src-pages-main-alt-js" */),
  "component---src-pages-main-credits-js": () => import("./../../../src/pages/main/credits.js" /* webpackChunkName: "component---src-pages-main-credits-js" */),
  "component---src-pages-main-emerging-landscape-js": () => import("./../../../src/pages/main/emergingLandscape.js" /* webpackChunkName: "component---src-pages-main-emerging-landscape-js" */),
  "component---src-pages-main-index-js": () => import("./../../../src/pages/main/index.js" /* webpackChunkName: "component---src-pages-main-index-js" */),
  "component---src-pages-main-market-landscape-js": () => import("./../../../src/pages/main/marketLandscape.js" /* webpackChunkName: "component---src-pages-main-market-landscape-js" */),
  "component---src-pages-main-narrative-js": () => import("./../../../src/pages/main/narrative.js" /* webpackChunkName: "component---src-pages-main-narrative-js" */),
  "component---src-pages-main-opportunities-js": () => import("./../../../src/pages/main/opportunities.js" /* webpackChunkName: "component---src-pages-main-opportunities-js" */),
  "component---src-pages-main-sticky-js": () => import("./../../../src/pages/main/sticky.js" /* webpackChunkName: "component---src-pages-main-sticky-js" */)
}

