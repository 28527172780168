import React from 'react';
import { useBrain } from "../../Brain";
import * as localStyles from "../styles/navbar.module.css"
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import useOnMobile from "../functions/useOnMobile";

import logoutIcon from "../images/logoutIcon.png"

function Navbar() {

    const onMobile = useOnMobile(610).onMobile
    const onMobile770 = useOnMobile(770).onMobile

    const { authReady, user, error, login, logout, loginFailed } = useBrain()

    const activeLinkColor = '#000000'

    return (

        <nav className={localStyles.navbar}>
            <div className={localStyles.ruler} />
            <div className={localStyles.ruler2} />

            {user &&
                <AniLink
                    fade
                    activeStyle={{ color: activeLinkColor }} to="/main"
                    className={localStyles.titleUnit}
                >
                    <h1>FUTURE OF VIDEO</h1>
                    <div className={localStyles.subTitle}>
                        Video is not just a format, it’s the interface.
                    </div>
                </AniLink>
            }

            {!user &&
                <div
                    className={localStyles.titleUnit}
                >
                    <h1>FUTURE OF VIDEO</h1>
                    <div className={localStyles.subTitle}>
                        Video is not just a format, it’s the interface.
                    </div>
                </div>
            }

            {user &&
                <div className={localStyles.pageLinks}>

                    <AniLink
                        fade
                        activeStyle={{
                            color: activeLinkColor,
                            textDecoration: 'underline',
                            textUnderlineOffset: '10px',
                            opacity: '1'




                        }} to="/main/narrative">

                        {!onMobile && <>Project </>}Intro


                    </AniLink>


                    <AniLink
                        fade
                        activeStyle={{
                            color: activeLinkColor,
                            textDecoration: 'underline',
                            textUnderlineOffset: '10px',
                            opacity: '1',
                        }} to="/main/emergingLandscape">{!onMobile && <>Emerging </>}Landscape</AniLink>

                    <AniLink
                        fade
                        activeStyle={{
                            color: '#249597',
                            textDecoration: 'underline',
                            textUnderlineOffset: '10px',
                            opacity: '1',
                        }}
                        to="/main/opportunities"
                    >
                        <span style={{ fontWeight: '600', color: '#249597' }}>Opportunities</span></AniLink>



                    {/* <AniLink
                        fade
                        to="/opportunities"
                        activeStyle={{
                            borderBottom: '1px solid #000000',

                        }}
                        style={{
                            fontWeight: '600',
                            background: '-webkit-linear-gradient(0deg, #5568D7, #B853AE)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            height: '22px',
                            borderBottom: '1px solid transparent'

                        }}>
                        Opportunities
                    </AniLink> */}




                    <AniLink
                        fade
                        activeStyle={{
                            color: activeLinkColor,
                            textDecoration: 'underline',
                            textUnderlineOffset: '10px',
                            opacity: '1'

                        }} to="/main/credits">{!onMobile && <>Methodology & </>}Credit</AniLink>


                    {user && !onMobile770 && <div id={localStyles.logoutButton} onClick={logout}>Logout</div>}
                    {user && onMobile770 && <img src={logoutIcon} className={localStyles.logoutIcon} onClick={logout} />}


                </div>

            }




        </nav >

    );
}

export default Navbar;

