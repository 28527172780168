import React from 'react';
import { useContext, useState } from 'react';
import { createContext } from 'react';
import useNetlifyIdentity from './src/functions/useNetlifyIdentity';

const defaultState = {
  data: {},
}

export const MyBrain = createContext(defaultState)
export const MyBrainProvider = ({ children }) => {

  const {
    authReady,
    user,
    error,
    login,
    logout,
    loginFailed
  } = useNetlifyIdentity()

  //only for development!!!
  // const user = true

  const [navbarHidden, setNavbarHidden] = useState(false)

  const [switchToEl3, setSwitchToEl3] = useState(false)


  const contextValues = {


    authReady,
    user,
    error,
    login,
    logout,
    loginFailed,

    navbarHidden,
    setNavbarHidden,

    switchToEl3,
    setSwitchToEl3,




  }

  return <MyBrain.Provider value={contextValues}>{children}</MyBrain.Provider>
}

export const useBrain = () => {
  const context = useContext(MyBrain)
  if (context === undefined || context === null) {
    throw new Error('useBrain must be called within Provider')
  }
  return context
}
