import React from "react";
import { useState, useEffect } from "react";

const useOnMobile = (threshold = 920) => {

    const [onMobile, setOnMobile] = useState()

    useEffect(() => {

        // const mediaQuery = window.matchMedia('(max-width: 920px)')
        // changeOnMobile(mediaQuery.matches)


        if (window.matchMedia && window.matchMedia(`(max-width: ${threshold}px)`).matches) {
            setOnMobile(true)

        }

        else {
            setOnMobile(false)
        }

        window.matchMedia(`(max-width: ${threshold}px)`).addEventListener('change', e => {
            setOnMobile(e.matches)

        });

    }, [])

    return { onMobile }



}

export default useOnMobile;



//not sure if the below is needed
//general size: below 1200px screen width
// const mediaQuery2 = window.matchMedia('(max-device-width: 1024px)')
// const [mobileDevice, setMobileDevice] = useState(mediaQuery2.matches)
// window.matchMedia('(max-device-width: 1024px)').addEventListener('change', e => {
//   setMobileDevice(e.matches)

// });



